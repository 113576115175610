.country_container{
    align-items: center;
    height: 500px;
    max-width: 350px;
    margin: 15px;
    overflow-y: scroll;
    background-color:whitesmoke;
    transition: all 455ms;
}

.country_container::-webkit-scrollbar{
    display: none;
}

.country_container:hover{
    box-shadow: 0px 0px 3px 3px  grey;
    transform: scale(1.02);
}

.card_header{
    margin: 0;
    padding: 0;
}

.flag{
    /* object-fit:contain; */
}

.card_body{
    margin: 15px;
    font-size: 16px;
    font-family:Arial, Helvetica, sans-serif;
    line-height: 1.5em;
}

.country_name{
    font-size: 25px;
    color: orange;
    padding-bottom: 20px;
    text-align: center;
}

.population{
    background-color: #61DBFB;
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 2px burlywood;
}

