.header__appinfo{
    text-align: center;
    line-height: 2.5em;
    font-size: x-large;
    height: 260px;
    background-image: linear-gradient(170deg,rgb(121, 12, 148), rgb(27, 93, 168));;
    color: wheat;
    position: top;
    width: 100%;
}

p{
    /* font-size: x-large; */
}

.header_title{
    font-family:'Courier New', Courier, monospace;
    font-size: 50px;
    font-weight: bolder;
    color: #61DBFB;
}