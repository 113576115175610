.countries_info{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    max-width: 100%;
}

.countriesapi_title{
    margin: 20px 20px;
    color: #61DBFB;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    text-align: center;
    padding-top: 30px;
}

.loader{
    color: orange;
    font-size: 25px;
    font-variant: small-caps;
    font-weight: bold;
    max-width:150px;
    margin-left: 50px;
    border-radius: 25px;
    padding-top: 20px; 
}

.loader h2{
    vertical-align: middle;
}

.form_countryInfo{
    margin: 20px;
    padding: 30px;
    text-align: center;
}

.textCountryName{
    width: 1000px;
    font-size: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 40px;
    border: 2px solid orange;
    border-color: orange;
    padding: 15px;
    background-color:blanchedalmond;
}

.countries_total{
    text-align: center;
    padding: 10px;
    font-size: 26px;
    font-family:Helvetica, sans-serif;
    font-weight: 400;
    color: orange;
    max-width: 500px;
    margin-left: 500px;
    align-self: center;
    /* border: 1px solid orange; */
    border-radius: 10px;
    line-height: 1.5em;
}

.error_msg{
    color: crimson;
    padding: 15px;
    font-size: 32px;
    font-family:Helvetica, sans-serif;
    font-weight: 600;

    border-radius: 15px;
    width: 800px;
    text-align: center;
   

}