.singlecat_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  /* height: 1200px; */
  margin-left: 25%;
  margin-right: 25%;
  height: fit-content;
  width: fit-content;
  word-wrap: break-word;
  box-shadow: 1px 1px 1px 1px grey;
  transition: all 0.1s;
}

.singlecat_card:hover {
  cursor: pointer;
  box-shadow: 3px 3px 3px 3px grey;
}

.img_header {
  object-fit: contain;
  overflow: hidden;
}

.img_header img {
  width: 100%;
  height: 100%;
  transition: transform 450ms;
}

.img_header:hover img {
  cursor: pointer;
  transform: scale(1.5);
}


.cat_image img {
  display: block;
  object-fit: contain;
  overflow: hidden;
}

.cat_image:hover img {
  cursor: pointer;
  transform: scale(1.2);
}

.singlecat_card_body {
  margin: 0px;
  padding: 30px 15px;
  font-size: x-large;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 2.5rem;
  text-align: justify;
  transition: transform 450ms;
}

.button:hover {
  cursor: pointer;
  background-color: orange;
  border: 0;
  color: white;
}

