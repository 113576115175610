.cats_header_title{
    color:rebeccapurple;
    text-align: center;
    font-size: xx-large;
    font-weight: 900;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 2px 2px 5px orange;
  }
  
  .cats_totals{
    color:darkorange;
    text-align: center;
    font-size: xx-large;
    font-weight: 900;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .cats_averages{
    color:slateblue;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

.loader {
    color: orange;
    font-size: 25px;
    font-variant: small-caps;
    font-weight: bold;
    max-width: 150px;
    margin-left: 50px;
    border-radius: 25px;
    padding-top: 150px;
    text-align: center;
  }
  
  .loader h2 {
    /* color: white; */
    /* vertical-align: middle; */
  }

  .cats__container{
      text-align: center;
  }
  