.home__title{
  margin: 50px 50px;
  color: #61DBFB;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
}

.about{
    margin: 50px 50px;
    text-align: justify;
    line-height: 1.5em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}