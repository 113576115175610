*{
  margin: 0px;
  max-width: 100%;
  flex-wrap: wrap;
}

::-webkit-scrollbar{
  width: 15px;
}

::-webkit-scrollbar-track{
  border-radius: 0px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb{
  border-radius: 0px;
  background-color: darkcyan;
}

.App{
  margin: 0px;
}

body{
  /* background-color:gold; */
}

