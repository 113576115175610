.num_box{
  max-height: 400px;
  max-width: 800px;
  margin-left: 300px;
}

.number-list{
    padding: 5px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100px;
    height: 100px;

    text-align: center;
    font-size: 5rem;
    color: white;
}

.box-display{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.num_box{
    /* text-align: center; */
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
    /* line-height: 1.5em; */
}

